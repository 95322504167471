import { LoginEventsState } from './reducers/login-events.reducer';
import { useSelector, shallowEqual } from 'react-redux';
import { SearchEventsState } from './reducers/search-events.reducer';
import { SortActionEventsState } from './reducers/sort-action-events.reducer';
import { ReloadEventsState } from '@features/analytics/redux/reducers/reload-events.reducer';
import { LdUserIdentityState } from './reducers/ld-user.reducer';
import { CashAdvanceEventsState } from './reducers/cash-advance-events.reducer';

export const useAnalyticsSelector = <TSelected>(selector: (state: NavCarrierState) => TSelected) => useSelector(selector, shallowEqual) as TSelected;

export const useLoginEvents = () => useAnalyticsSelector<LoginEventsState>((state: NavCarrierState) => state.analytics.login);
export const useSearchEvents = () => useAnalyticsSelector<SearchEventsState>((state: NavCarrierState) => state.analytics.search);
export const useSortActionEvents = () => useAnalyticsSelector<SortActionEventsState>((state: NavCarrierState) => state.analytics.sort);
export const useReloadsEvents = () => useAnalyticsSelector<ReloadEventsState>((state: NavCarrierState) => state.analytics.reload);
export const useLdUserSelector = () => useAnalyticsSelector<LdUserIdentityState>((state: NavCarrierState) => state.analytics.ldUser);
export const useCashAdvanceEvents = () => useAnalyticsSelector<CashAdvanceEventsState>((state: NavCarrierState) => state.analytics.cashAdvance);
