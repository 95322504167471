export enum AvailableLoadOfferStatus {
  OPEN = 'OPEN',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  CLOSED = 'CLOSED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  COUNTERED = 'COUNTERED'
}

export default AvailableLoadOfferStatus;
